<template>
    <div
        v-bind="wrapperProps"
    >
        <audio
            controls
            :autoplay="autoplay"
            :name="item.title"
        >
            <source :src="item.url" type="audio/mpeg">
        </audio>
    </div>
</template>

<script>
import mediaViewMixin from '@apps/media/mixins/mediaViewMixin'

export default {
    name: 'AudioView',
    mixins: [ mediaViewMixin ],
    props: {
        autoplay: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        wrapperProps() {
            const classes = ['media-audio-view']

            if(this.fullscreen) {
                classes.push('media-audio-view--' + 'fullscreen')

                if(this.winWidth > this.winHeight) {
                    classes.push('media-audio-view--' + 'album')
                } else {
                    classes.push('media-audio-view--' + 'portrait')
                }
            }

            return {
                class: classes
            }
        }
    }
}
</script>

<style lang=scss>
    .media-audio-view {
        &--fullscreen {
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

</style>
